import { getLang } from '@petr-jilek/fastdo-react-localization'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import AnimatedBackground from './AnimatedBackground'
import FdHelmet from './FdHelmet'
import Footer from './Footer'

export interface Props {
  title: string
  children: React.ReactNode
  scroolToTop?: boolean
}

export default function PageBase({ title, children, scroolToTop = false }: Props) {
  useEffect(() => {
    if (scroolToTop) window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <FdHelmet HeadElement={Helmet} title={`${title} - Soňa Klapková`} lang={getLang()} />
      <section className="min-h-screen px-8 pt-12 pb-32">
        <div className="m-auto max-w-2xl">
          <h1>{title}</h1>
          {children}
        </div>
      </section>
      <Footer />
      <AnimatedBackground />
    </>
  )
}
