import styles from './AnimatedBackground.module.css'

import { useEffect, useState } from 'react'

type Point = {
  x0: number
  y0: number
}

export default function AnimatedBackground() {
  const [points, setPoints] = useState<Point[]>([])

  useEffect(() => {
    const points: Point[] = []
    for (let i = 0; i < 20; i++) {
      points.push({
        x0: -Math.random() * window.innerWidth,
        y0: -Math.random() * window.innerHeight
      })
    }
    setPoints(points)
  }, [])

  return (
    <div className={styles.component}>
      {points.map((point, i) => {
        return <div key={i} className={styles.point} style={{ left: point.x0, top: point.y0 }} />
      })}
    </div>
  )
}
