import './styles/index.css'
import 'fastdo-react/dist/styles/app.css'
import 'fastdo-react/dist/styles/components.css'
import 'fastdo-react/dist/styles/index.css'

import ReactDOM from 'react-dom/client'

import AppRouter from './app/AppRouter'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<AppRouter />)
