import { Card, Spacer } from 'fastdo-react'

import { useAppT } from '../localization/config'
import Email from './Email'
import PhoneNumber from './PhoneNumber'

export default function Footer() {
  const t = useAppT()

  return (
    <footer className="bg-primary p-8">
      <div className="m-auto max-w-2xl">
        <div className="[&_a]:text-light [&_p]:text-light">
          <h2 className="text-light m-0">{t.contact_title}</h2>
          <div className="h-5" />
          <p>{t.name}</p>
          <div className="h-3" />
          <PhoneNumber shorten />
          <div className="h-3" />
          <Email shorten />
          <div className="h-8" />
        </div>
        <div>
          <Card>
            <div className="[&_p]:text-dark">
              <div className="flex items-center">
                <img src="/assets/images/review/gymnazium-srobarova.svg" alt="Logo" className="w-12 h-12" />
                <Spacer horizontal size={20} />
                <h3>Gymnázium Šrobárova Košice</h3>
              </div>
              <Spacer />
              <div className="[&_p]:text-sm">
                <p>
                  Naša skúsenosť so sprievodkyňou pani Soňou Klapkovou, počas školského výletu v Prahe, bola vynikajúca.
                  Absolvovala som školský výlet so žiakmi gymnázia z Košíc a pani Soňa pre nás pripravila zaujímavý
                  štvorhodinový program. Prvé dve hodiny sme prešli Staré Mesto, kde nám podala zaujímavé informácie o
                  histórii a kultúre Prahy.
                </p>
                <Spacer />
                <p>
                  Nasledovala dvojhodinová plavba na krásnej secesnej lodi, ktorá začala bohatým rautom. Jedlo bolo
                  veľmi chutné a ideálne načasované po dvoch hodinách chodenia. Záverečná hodina na lodi bola venovaná
                  výkladu s úžasnými výhľadmi na Prahu. Plavba pod Karlovým mostom bola nezabudnuteľná.
                </p>
                <Spacer />
                <p>
                  Oceňujeme profesionálny prístup pani Soni, jej zanietenie a schopnosť zaujať žiakov. Bola som veľmi
                  spokojná s jej službami a určite ju odporúčam každému, kto plánuje návštevu Prahy. Ďakujeme za skvelý
                  zážitok!
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </footer>
  )
}
