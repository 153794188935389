import { LocalizedNavigate, getLangPrefix } from '@petr-jilek/fastdo-react-localization'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import AboutPage from '../pages/AboutPage'
import ContactPage from '../pages/ContactPage'
import HomePage from '../pages/HomePage'
import RoutesPage from '../pages/RoutesPage'
import ServicesPage from '../pages/ServicesPage'
import NotFound from '../views/NotFound'
import App from './App'
import AppLocalization from './AppLocalization'

export default function AppRouter() {
  const createAppRoutes = (lang: string) => {
    const prefix = getLangPrefix(lang)

    return {
      path: prefix,
      element: <AppLocalization lang={lang} />,
      children: [
        {
          path: '',
          element: <App />,
          children: [
            { path: '', element: <HomePage /> },
            { path: 'about', element: <AboutPage /> },
            { path: 'services', element: <ServicesPage /> },
            { path: 'routes', element: <RoutesPage /> },
            { path: 'contact', element: <ContactPage /> }
          ]
        },
        { path: '404', element: <NotFound /> }
      ],
      errorElement: <LocalizedNavigate to="/404" replace />
    }
  }

  const router = createBrowserRouter([createAppRoutes('cs'), createAppRoutes('sk'), createAppRoutes('en')])

  return <RouterProvider router={router} />
}
