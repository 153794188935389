import { Card, Spacer } from 'fastdo-react'

import Email from '../components/Email'
import MoveUpAnimation from '../components/MoveUpAnimation'
import PageBase from '../components/PageBase'
import PhoneNumber from '../components/PhoneNumber'
import { useAppT } from '../localization/config'

export default function ContactPage() {
  const t = useAppT()

  return (
    <PageBase title={t.contact_title} scroolToTop>
      <MoveUpAnimation>
        <p className="mb-4">{t.contact_text}</p>
        <Card>
          <PhoneNumber />
          <Spacer />
          <Email />
        </Card>
      </MoveUpAnimation>
    </PageBase>
  )
}
