import { getLang, useT } from '@petr-jilek/fastdo-react-localization'
import Helmet from 'react-helmet'
import { Outlet } from 'react-router-dom'

import FdHelmet from '../components/FdHelmet'
import Navbar from '../components/Navbar'

export default function App() {
  const t = useT()

  return (
    <main>
      <FdHelmet
        HeadElement={Helmet}
        title={t.helmet_title}
        image={'https://sonaklapkova.cz/assets/images/logo512.png'}
        description={t.helmet_description}
        lang={getLang()}
      />

      <Navbar />
      <Outlet />
    </main>
  )
}
