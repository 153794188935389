import { LocalizedLink, useT } from '@petr-jilek/fastdo-react-localization'
import { NotFoundView } from 'fastdo-react'

export default function NotFound() {
  const t = useT()

  return (
    <NotFoundView
      header={t.not_found_title}
      text={t.not_found_text}
      actionComponent={
        <LocalizedLink to="/" className="fd-btn fd-btn-primary">
          {t.home_title}
        </LocalizedLink>
      }
    />
  )
}
