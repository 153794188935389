import { type ReactNode } from 'react'

export interface Props {
  HeadElement: any
  lang?: string
  title: string
  url?: string
  image?: string
  description?: string
  ogType?: string
  largeTwitterCard?: boolean
  noIndex?: boolean
  refferrer?: string
  children?: ReactNode
}

const FdHelmet: React.FC<Props> = ({
  HeadElement,
  lang = 'en',
  title,
  url,
  image,
  description,
  ogType = 'website',
  largeTwitterCard = false,
  noIndex = false,
  refferrer = 'origin-when-crossorigin',
  children = null
}: Props) => {
  const metaRobots = noIndex ? 'noindex, nofollow' : 'index, follow'
  const twitterCardType = largeTwitterCard ? 'summary_large_image' : 'summary'

  const canonical = url ?? window.location.href

  return (
    <HeadElement>
      <html lang={lang} />
      <title>{title}</title>
      <link rel="canonical" href={canonical} />
      <meta name="robots" content={metaRobots} />
      {description && <meta name="description" content={description} />}

      {/* OG Tags */}
      {/* https://ogp.me/ */}
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonical} />
      <meta property="og:type" content={ogType} />
      {image && <meta property="og:image" content={image} />}
      {description && <meta property="og:description" content={description} />}

      {/* Twitter tags */}
      {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started */}
      <meta property="twitter:title" content={title} />
      <meta property="twitter:card" content={twitterCardType} />
      {description && <meta property="twitter:description" content={description} />}
      {image && <meta property="twitter:image" content={image} />}

      {/* https://moz.com/blog/meta-referrer-tag */}
      <meta name="referrer" content={refferrer} />

      {children}
    </HeadElement>
  )
}

export default FdHelmet
