import MoveUpAnimation from '../components/MoveUpAnimation'
import PageBase from '../components/PageBase'
import { useAppT } from '../localization/config'

export default function ServicesPage() {
  const t = useAppT()

  return (
    <PageBase title={t.services_title} scroolToTop>
      <MoveUpAnimation>
        <ul className="list-[circle]">
          <li>{t.services_item_1}</li>
          <li>{t.services_item_2}</li>
          <li>{t.services_item_3}</li>
          <li>{t.services_item_4}</li>
        </ul>
      </MoveUpAnimation>
    </PageBase>
  )
}
