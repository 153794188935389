import { DOMKeyframesDefinition, DynamicAnimationOptions, useAnimate, useInView } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

export interface Props {
  akey?: string
  init?: DOMKeyframesDefinition
  keyFrames?: DOMKeyframesDefinition
  initOptions?: DynamicAnimationOptions
  options?: DynamicAnimationOptions
  repeat?: boolean
  children: React.ReactNode
}

export default function InViewAnimation({
  akey = 'fm-animation-div',
  init = { opacity: 0 },
  keyFrames = { opacity: 1 },
  initOptions = { duration: 0 },
  options = { duration: 0.4 },
  repeat = false,
  children
}: Props) {
  const [scope, animate] = useAnimate()
  const motionDiv = useRef(null)
  const isInView = useInView(motionDiv, { once: !repeat })

  useEffect(() => {
    if (isInView) {
      animate(`#${akey}`, keyFrames, options)
    } else {
      animate(`#${akey}`, init, initOptions)
    }
  }, [isInView, animate, akey, init, keyFrames, initOptions, options])

  return (
    <div ref={scope}>
      <div ref={motionDiv} id={akey}>
        {children}
      </div>
    </div>
  )
}
