import { useT } from '@petr-jilek/fastdo-react-localization'
import { LangSaveType, LocalizationModel, localizationSettings } from '@petr-jilek/fastdo-react-localization'

import { Translations } from './Translations'

localizationSettings.default = 'cs'
localizationSettings.langs = ['cs', 'sk', 'en']
localizationSettings.type = LangSaveType.url

export const localizationModel: LocalizationModel = {
  cs: require('./translations/cs.json'),
  sk: require('./translations/sk.json'),
  en: require('./translations/en.json')
}

export const useAppT = () => useT<Translations>()
