import { useAppT } from '../localization/config'
import Wrapper from './Wrapper'

export default function PhoneNumber({ shorten = false, anim = false }: { shorten?: boolean; anim?: boolean }) {
  const t = useAppT()

  return (
    <Wrapper
      wrap={!shorten}
      wrapper={(children) => (
        <p>
          {t.contact_phone} {children} ({t.contact_time})
        </p>
      )}
    >
      <a href="tel:+420737557335" className={anim ? 'a-anim' : ''}>
        +420 737 557 335
      </a>
    </Wrapper>
  )
}
