import MoveUpAnimation from '../components/MoveUpAnimation'
import PageBase from '../components/PageBase'
import { useAppT } from '../localization/config'

export default function RoutesPage() {
  const t = useAppT()

  return (
    <PageBase title={t.routes_title} scroolToTop>
      <MoveUpAnimation>
        <h2>{t.route1_title}</h2>
        <p dangerouslySetInnerHTML={{ __html: t.route1_text }}></p>
      </MoveUpAnimation>

      <MoveUpAnimation>
        <h2>{t.route2_title}</h2>
        <p dangerouslySetInnerHTML={{ __html: t.route2_text }}></p>
      </MoveUpAnimation>
    </PageBase>
  )
}
