import { LocalizedLink } from '@petr-jilek/fastdo-react-localization'
import { Navbar as FdNavbar, Spacer } from 'fastdo-react'
import { PositionType } from 'fastdo-react/dist/esm/components/nav/NavbarBase'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppT } from '../localization/config'

export default function Navbar() {
  const t = useAppT()
  const [closer, setCloser] = useState(false)

  return (
    <FdNavbar
      LinkElement={LocalizedLink}
      navItems={[
        { linkProps: { to: '/' }, label: t.home_title },
        { linkProps: { to: '/about' }, label: t.about_title },
        { linkProps: { to: '/routes' }, label: t.routes_title },
        { linkProps: { to: '/services' }, label: t.services_title },
        { linkProps: { to: '/contact' }, label: t.contact_title }
      ]}
      closer={closer}
      navbarBaseProps={{
        positionType: PositionType.fixed,
        headerChildren: (
          <LocalizedLink to="/" style={{ color: 'var(--fd-primary-color)', margin: 0, fontSize: '1.4rem' }}>
            Soňa Klapková
          </LocalizedLink>
        ),
        actionChrildren: (
          <div
            style={{
              display: 'flex'
            }}
          >
            <Link to="/" onClick={() => setCloser((_) => !_)}>
              <span className="fi fi-cz" style={{ color: 'transparent' }}>
                cs
              </span>
            </Link>
            <Spacer horizontal />
            <Link to="/sk" onClick={() => setCloser((_) => !_)}>
              <span className="fi fi-sk" style={{ color: 'transparent' }}>
                sk
              </span>
            </Link>
            <Spacer horizontal />
            <Link to="/en" onClick={() => setCloser((_) => !_)}>
              <span className="fi fi-us" style={{ color: 'transparent' }}>
                en
              </span>
            </Link>
          </div>
        ),
        marginTopOpen: 100,
        smallScreen: 1100,
        styles: {
          smallScreenAction: { paddingTop: '20px' }
        }
      }}
    />
  )
}
