import MoveUpAnimation from '../components/MoveUpAnimation'
import PageBase from '../components/PageBase'
import { useAppT } from '../localization/config'

export default function AboutPage() {
  const t = useAppT()

  return (
    <PageBase title={t.about_title} scroolToTop>
      <MoveUpAnimation>
        <div className="overflow-hidden sm:grid sm:grid-cols-[2fr_1fr] sm:gap-8">
          <div className="mb-8 flex items-center align-middle sm:mb-0">
            <p>{t.about_text}</p>
          </div>
          <div className="flex items-center">
            <img
              src="/assets/images/profile.webp"
              alt="Soňa Klapková"
              width="1179"
              height="1174"
              title="Soňa Klapková"
              loading="eager"
              className="rounded-2xl"
            />
          </div>
        </div>
      </MoveUpAnimation>
    </PageBase>
  )
}
