import { LocalizedLink } from '@petr-jilek/fastdo-react-localization'

import InViewAnimation from '../components/InViewAnimation'
import { useAppT } from '../localization/config'

export default function HomePage() {
  const t = useAppT()

  return (
    <section className="min-h-screen flex items-center justify-center">
      <video autoPlay loop muted playsInline className="fixed inset-0 w-full h-full object-cover z-[-10] brightness-50">
        <source src="/assets/videos/intro.mp4" type="video/mp4" />
      </video>

      <div className="max-w-2xl p-8 text-center">
        <InViewAnimation>
          <h1 className="text-light font-medium text-4xl/relaxed sm:text-5xl/relaxed md:text-6xl/relaxed">{t.title}</h1>
          <div className="h-8"></div>
          <LocalizedLink className="fd-btn fd-btn-primary" to="/contact">
            {t.contact_title}
          </LocalizedLink>
        </InViewAnimation>
      </div>
    </section>
  )
}
