import { useAppT } from '../localization/config'
import Wrapper from './Wrapper'

export default function Email({ shorten = false, anim = false }: { shorten?: boolean; anim?: boolean }) {
  const t = useAppT()

  return (
    <Wrapper
      wrap={!shorten}
      wrapper={(children) => (
        <p>
          {t.contact_email} {children}
        </p>
      )}
    >
      <a href="mailto: klapkovasona@seznam.cz" className={anim ? 'a-anim' : ''}>
        klapkovasona@seznam.cz
      </a>
    </Wrapper>
  )
}
