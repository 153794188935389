import { LocalizationContextProvider } from '@petr-jilek/fastdo-react-localization'
import { Outlet } from 'react-router-dom'

import { Translations } from '../localization/Translations'
import { localizationModel } from '../localization/config'

export interface Props {
  lang: string
}

export default function AppLocalization({ lang }: Props) {
  return (
    <LocalizationContextProvider<Translations> lang={lang} model={localizationModel}>
      <Outlet />
    </LocalizationContextProvider>
  )
}
