import InViewAnimation from './InViewAnimation'

export interface Props {
  key?: string
  children: React.ReactNode
}

export default function MoveUpAnimation({ key = 'fm-animation-div', children }: Props) {
  return (
    <InViewAnimation
      key={key}
      init={{ marginTop: 150, opacity: 0 }}
      keyFrames={{ marginTop: 0, opacity: 1 }}
      options={{ duration: 0.4 }}
    >
      {children}
    </InViewAnimation>
  )
}
